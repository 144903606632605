import PermissionsFormStore from '@/modules/permissions/store/permissions-form-store';
import PermissionsListStore from '@/modules/permissions/store/permissions-list-store';

export default {
    namespaced: true,

    modules: {
        form: PermissionsFormStore,
        list: PermissionsListStore,
    },
};
