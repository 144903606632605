import ApiService from '@/shared/services/api.service';

export class PermissionsService {
    static listPermissions(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/permissions', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static addPermissionsToOrganisation(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/organisations/${id}`, { permissions: data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static fetchOrganisation(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`organisations/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static fetchPermissionsByOrgScope() {
        return new Promise((resolve, reject) => {
            ApiService.query('/permissions', {
                params: {
                    query: `scope eq organisation and system_default eq false`,
                    limit: 100
                },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }

}
